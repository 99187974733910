export interface ActivityChange {
  id: string;
  domainName: string;
  domainKey: number;
  changes: Array<ActivityChangeItem>;
  changeSeq: number;
  changeTS: string;
  receivedTS: string;
  action: string;
  changedBy: string;
}

export interface ActivityChangeItem {
  type?: string;
  path?: string;
  from?: string | boolean | number | Array<any>;
  to?: string | boolean | number | Array<any>;
  changes?: Array<ActivityChangeItem>;
}

export interface UIActivityAction {
  id: string;
  changeTS: string;
  changedBy: string;
  activity: string;
  rowNum?: string;
  originalValue: string;
  newValue: string;
  activityType: string;
  status: string;
  context?: string;
}
