import { Observable } from 'rxjs';

export interface ChangeItemModel {
  type?: string;
  path?: Array<string>;
  from?: string | boolean | number | object | Array<string | boolean | number | object>;
  to?: string | boolean | number | object | Array<string | boolean | number | object>;
}

export interface ChangeModel {
  id: string;
  domainName: string;
  domainKey: number;
  changes?: Array<ChangeItemModel>;
  changeSeq: number;
  changeTS: string;
  receivedTS: string;
  action: string;
  changedBy: string;
  beforeParentDomainName?: string;
  beforeParentDomainKey?: number;
  afterParentDomainName?: string;
  afterParentDomainKey?: number;
  afterContextVal?: object;
  beforeContextVal?: object;
}

export interface ChangeModelResponse {
  restartRowNum: string;
  items: ChangeModel[];
}

export interface ActivityModel {
  id?: string;
  domainName?: string;
  domainKey?: number;
  actionMsg?: string;
  actionMsgNum?: number;
  actionType?: string;
  actionStatus?: string;
  actionMeta?: object;
  actionTS?: string;
  actionBy?: string;
  receivedTS?: string;
}

export interface ChangeLogRequestConfigI {
  entityId: any;
  parentId?: any;
  params?: {
    [key: string]: any;
  };
}

export interface ChangeLogFilter {
  fieldNameValue: string;
  status?: string[];
  activityType?: string[];
  userType: string[];
  orderStatus?: string[];
}

export interface ChangeLogServiceI {
  fetchLogs(config: ChangeLogRequestConfigI): Observable<Array<ChangeModel>>;

  fetchActivity(config: ChangeLogRequestConfigI): Observable<Array<ActivityModel>>;

  pathToFieldName(key: string): string;

  shouldInclude(fieldName: string): boolean;

  getExcludeFields(): Array<string>;

  getEntityName?(log: ChangeModel): string;

  buildActivityColumn?(path: string, log: ChangeModel): string;

  fetchLogsWithPagination?(config: ChangeLogRequestConfigI): Observable<ChangeModelResponse>;
}
