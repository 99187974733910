import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { msalGuard } from '../msal/msal.guard';
import { resolveLayoutData } from '../shared/shared-access-module/resolver/layout/layout-access-resolver';
import { ChangePasswordFormComponent } from '../shared/shared-components/change-password-form/change-password-form.component';
import { TermsConditionsDialogComponent } from '../shared/shared-components/terms-conditions-dialog/terms-conditions-dialog.component';
import { featureFlagGuard } from '../shared/shared-guard/feature-flag/feature-flag.guard';
import { maintenanceModeGuard } from '../shared/shared-guard/maintenance-mode/maintenance-mode.guard';
import { profileCheckGuard } from '../shared/shared-guard/profile-check/profile-check-guard.service';
import { routeActivationGuard } from '../shared/shared-guard/route-activation/route-activation.guard';
import { NotAuthorizedComponent } from './home/unauthorized.page/not-authorized.component';
import { LogoutComponent } from './logout/logout.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    children: [
      { path: 'tc', component: TermsConditionsDialogComponent },
      { path: 'changepwd', component: ChangePasswordFormComponent },
    ],
  },
  {
    path: '',
    canActivate: [msalGuard],
    resolve: [resolveLayoutData],
    component: LayoutComponent,
    canActivateChild: [msalGuard],
    children: [
      {
        path: 'unauthorized',
        canActivate: [msalGuard],
        component: NotAuthorizedComponent,
      },
    ],
  },
  {
    path: 'home',
    canActivate: [msalGuard],
    canActivateChild: [routeActivationGuard('home'), profileCheckGuard, maintenanceModeGuard],
    component: LayoutComponent,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'admin/mdm',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('mdm'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () => import('./mdm/mdm.module').then((m) => m.MdmModule),
  },
  {
    path: 'admin/scheduler',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('sched'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./scheduler/scheduler.module').then((m) => m.SchedulerModule),
  },
  {
    path: 'admin/edi',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('edi'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () => import('./edi/edi.module').then((m) => m.EdiModule),
  },
  {
    path: 'admin/lms',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('lms'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () => import('./workforce/workforce.module').then((m) => m.WorkforceModule),
  },
  {
    path: 'members',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('members'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./members/members.module').then((m) => m.MembersModule),
  },
  {
    path: 'payers',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('payers'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./payers/payers.module').then((m) => m.PayersModule),
  },
  {
    path: 'payments/remittance',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('payments'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'enable-payment-module'},
    loadChildren: () =>
      import('./payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: 'payments',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('deposits'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'enable-payment-module' },
    loadChildren: () =>
      import('./deposits/deposits.module').then((m) => m.DepositsModule),
  },
  {
    path: 'payments/reconcile',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('recon'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'enable-payment-module' },
    loadChildren: () =>
      import('./pay-recon/pay-recon.module').then((m) => m.PayReconModule),
  },
  {
    path: 'payments/disbursement',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('disburse'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'enable-payment-module' },
    loadChildren: () =>
      import('./disburse/disburse.module').then((m) => m.DisburseModule),
  },
  {
    path: 'orders',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('orders'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'mcq',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('mcq'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () => import('./mcq/mcq.module').then((m) => m.McqModule),
  },
  {
    path: 'reports',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('reports'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'uploads/payers/orders',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('payer-order-upload'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./int-payer-order/int-payer-order.module').then(
        (m) => m.IntPayerOrderModule
      ),
  },
  {
    path: 'auth-error',
    canActivateChild: [maintenanceModeGuard],
    loadChildren: () =>
      import('./auth-error/auth-error.module').then((m) => m.AuthErrorModule),
  },
  {
    path: 'not-found',
    resolve: [resolveLayoutData],
    component: LayoutComponent,
    canActivateChild: [profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'maintenance',
    resolve: [resolveLayoutData],
    component: LayoutComponent,
    loadChildren: () =>
      import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule)
  },
  {
    path: 'help-center',
    canActivate: [msalGuard],
    component: LayoutComponent,
    canActivateChild: [routeActivationGuard('help-center'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./help-center/help-center.module').then(
        (m) => m.HelpCenterModule
      ),
  },
  {
    path: 'providers',
    component: LayoutComponent,
    canActivate: [msalGuard],
    canActivateChild: [routeActivationGuard('providers'), profileCheckGuard, maintenanceModeGuard],
    loadChildren: () =>
      import('./providers/providers.module').then((m) => m.ProvidersModule),
  },
  {
    path: 'feeschedules',
    component: LayoutComponent,
    canActivate: [msalGuard],
    canActivateChild: [routeActivationGuard('feeschedules'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'PQ-70-enable-fee-schedule' },
    loadChildren: () =>
      import('./fee-schedules/fee-schedules.module').then(m => m.FeeSchedulesModule)
  },
  {
    path: 'feeschedules/admin',
    component: LayoutComponent,
    canActivate: [msalGuard],
    canActivateChild: [routeActivationGuard('int-fee'), profileCheckGuard, featureFlagGuard, maintenanceModeGuard],
    data: { featureFlag: 'enable-payment-module' },
    loadChildren: () =>
      import('./int-fee/int-fee.module').then(m => m.IntFeeModule)
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];
